export default class Kakao {

    constructor() {
        this._Kakao = window.Kakao;

        if (!this._Kakao.isInitialized())
            this._Kakao.init('88ad4109566a02a9ea6fe07ffa5aaab0');

        this.accessToken = null;
        this.refreshToken = null;
    }

    signIn() {
        return new Promise((resolve, reject) => {
            // console.log("kakao.signIn");
            this._Kakao.Auth.login({
                throughTalk: true,
                persistAccessToken: true,
                persistRefreshToken: true,
                scope: "account_email",
                success: (res) => {
                    const json = JSON.stringify(res);
                    const oauthKakao = "https://asia-northeast1-void-tee-time.cloudfunctions.net/oauthKakao";

                    this.accessToken = res.access_token;
                    this.refreshToken = res.refresh_token;

                    fetch(oauthKakao, {
                        method: 'POST',
                        mode: 'cors',
                        headers: { "Content-Type": "application/json" },
                        body: json
                    })
                        .then((_res) => _res.json())
                        .then((_data) => {
                            if (_data.status === '1' && _data.token) {
                                const token = _data.token;
                                resolve(token);
                            }
                            else if (_data.status === '-2') {
                                alert("카카오 계정 이메일 제공에 동의해주세요. 아이디로 사용됩니다.");
                                reject('oauthKakao email not provided');
                            }
                            else
                                reject('oauthKakao authentication failed');
                        })
                        .catch((err) => {
                            reject(err);
                        })
                },
                fail: (err) => {
                    reject(err);
                }
            });
        });
    }

    signOut() {
        return new Promise((resolve, _) => {
            this._Kakao.Auth.logout(() => {
                resolve();
            });
        });
    }

    extendToken = () => {
        this._Kakao.API.request({
            url: "/oauth/token",
            data: {
                client_id: 'd072b8bfd42dabe13bc24a151706a0bd',
                grant_type: 'refresh_token',
                refresh_token: ''
            }
        }).then((r) => {
            console.log(r);
        }).catch((err) => {
            console.log(err);
        })
    }

    _getStatusInfo = () => {
        console.log("kakao.getStatusInfo");
        const token = this._Kakao.Auth.getAccessToken();
        if (!token)
            return new Promise((resolve, reject) => reject(false));

        return this._Kakao.API.request({
            url: '/v1/user/access_token_info'
        })
            .then((res) => res)
            .catch((err) => {
                if (err.code === -401)
                    return this.extendToken();
            });
    }

    // authorize() {
    //     const redirectUri = 'https://asia-east2-void-tee-time.cloudfunctions.net/oauthKakao';
    //     const scope = "account_email,talk_message";
    //     // const url = oauth/authorize?client_id={app_key}&redirect_uri={redirectUri}&response_type=code&scope=account_email,talk_message;

    //     console.log("authorize");
    //     this._Kakao.API.request({
    //         url: "oauth/authorize",
    //         data: {
    //             client_id : 'd072b8bfd42dabe13bc24a151706a0bd',
    //             redirect_uri: redirectUri,
    //             response_type: "code",
    //             scope: scope,
    //         }
    //     }).then((r) => {
    //         console.log(r);
    //     }).catch((err) => {
    //         console.log(err);
    //     })
    // }

    getStatusInfo = () => {
        // console.log("kakao.getStatusInfo");
        return new Promise((resolve, reject) => {
            this._Kakao.Auth.getStatusInfo((res) => {
                if (res.status === 'connected' && res.user)
                    resolve(res.user);
                else if (res.status === 'not_connected')
                    reject(null);
            });
        });
    }

    sendKakaoCustomLink(data) {
        const hasImages = data._info.images && data._info.images.length > 0;
        const templateId = (hasImages) ? 18453 : 18623;
        const img_1 = (hasImages) ? data._info.images[0] : 'https://teetime.cc/img-512-maskable.png';
        const img_2 = (hasImages && data._info.images.length > 1) ? data._info.images[1] : 'https://teetime.cc/img-512-maskable.png';

        let title = data._name;
        let description = '';

        if (data._address)
            description = data._address.address_name;
        if (data._tel)
            description += ' (' + data._tel + ')';

        switch (data._ttype) {
            case 'CC':
                title += ' | 골프장';
                break;
            case 'OD':
                title += ' | 실외 골프 연습장';
                break;
            default:
                title += ' | 스크린 골프장';
                break;
        }

        // if (data._address)
        // title += ' | ' + data._address.address_name;
        // description += ' - ' + data._address.address_name + ' ' + (data._address.building_name? data._address.building_name : '');

        const message = {
            templateId: templateId,
            templateArgs: {
                '_id': data._id,
                'title': title,
                'description': description,
                'img_1': img_1,
                'img_2': img_2,
            }
        };

        this._Kakao.Link.sendCustom(message);
    }

    // sendKakaoLink(data) {
    //     const url = 'https://teetime.cc/' + data._id;
    //     const imageUrl = (data._info.images && data._info.images.length > 0)? data._info.images[0] : 'https://teetime.cc/img-default.png';

    //     let description = data._name;
    //     if (data._address)
    //         description += ' - ' + data._address.address_name + ' ' + (data._address.building_name? data._address.building_name : '');

    //     const message = {
    //         objectType: 'feed',
    //         content: {
    //             title: '[티타임] 스크린 골프 라운딩 나가실래요?',
    //             description: description,
    //             imageUrl: imageUrl,
    //             link: {
    //                 mobileWebUrl: url,
    //                 webUrl: url
    //             }
    //         },
    //         buttonTitle: '바로 확인'
    //     };

    //     this._Kakao.Link.sendDefault(message);
    // }

    addPlusFriend() {
        this._Kakao.Channel.addChannel({
            channelPublicId: '_pEdxaT'
        });
    }

    chatPlusFriend() {
        this._Kakao.Channel.chat({
            channelPublicId: '_pEdxaT'
        });
    }

    navigate(data) {
        if (!data._location || !data._location.coordinates)
            return false;

        //const coords = data._location.coordinates;
        //console.log(coords);
        // this._Kakao.Navi.start({
        //     name: data._name,
        //     x: coords[0],
        //     y: coords[1],
        //     coordType: 'wgs84'
        // });
    }

    sendMessage(data) {
        const url = "https://teetime.cc/" + data.id;
        const imageUrl = 'https://teetime.cc/img-512-maskable.png';

        this._Kakao.API.request({
            url: "/v2/api/talk/memo/default/send",
            data: {
                template_object: {
                    object_type: "feed",
                    content: {
                        title: "[티타임] 스크린 벙개 모집 등록 알림",
                        description: data.data._name + ' ' + data.date + ' ' + data.time,
                        image_url: imageUrl,
                        link: {
                            mobileWebUrl: url,
                            webUrl: url
                        }
                    },
                    buttons: [
                        {
                            title: "다시 확인",
                            link: {
                                mobileWebUrl: url,
                                webUrl: url
                            }
                        }
                    ]
                }
            }
        }).then(() => {
            //console.log("sendMessage");
        }).catch((err) => {
            console.log(err);
        });
    }
}