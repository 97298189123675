import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter } from "react-router";
import ReactGA from 'react-ga4';

import './App.css';
import { AppContextProvider } from './class/AppContext.jsx';
import Main from './components/Main.jsx';

const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-R2YWYG5DL1');
  });

  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Main />} />
          {/* <Route exact path="/oauthNaver" render={(props) => <Auth {...props} />} /> */}
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;