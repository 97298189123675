import React, { useContext, useState, useEffect, useRef } from 'react';
import ttContext from '../class/AppContext.jsx'
import { AuthButton } from './Account.jsx';
import { getDownloadURL } from 'firebase/storage';

const toDate = (ts) => {
    const yyyy = ts.getFullYear();
    const mm = ('0' + (ts.getMonth() + 1)).slice(-2);
    const dd = ('0' + ts.getDate()).slice(-2);

    return yyyy + '-' + mm + '-' + dd;
}

// const ReviewAttachForm = (props) => {
//     const { handleImages } = props;
//     const { backend } = useContext(ttContext);
//     const refFile = useRef(null);

//     // const [ isLoading, setIsLoading ] = React.useState(true);
//     const [ disabled, setDisabled ] = React.useState(false);
//     const [ images, setImages ] = React.useState([]);
//     const [ message, setMessage ] = useState(null);

//     const _onClick = () => {
//         if (images.length < 4)
//             refFile.current.click();
//     }

//     const _onAttach = (e) => {
//         // setIsLoading(true);
//         setDisabled(true);

//         const file = e.target.files[0];
//         const uploadTask = backend.uploadImage(file);

//         uploadTask.on('state_changed', 
//             (snapshot) => {
//                 let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                 if (snapshot.state === 'running')
//                     setMessage(`업로드 중 ${progress}%`);
//             }, 
//             (err) => {
//                 console.error('_onAttach', err);
//                 setDisabled(false);
//             },
//             () => {
//                 uploadTask.snapshot.ref.getDownloadURL()
//                     .then((url) => {
//                         images.push(url);
//                         setImages([ ...images ]);
//                         // setMessage('업로드 완료');
//                         setDisabled(false);
//                     });
//             }
//         );
//     }

//     const _onDelete = (i) => {
//         images.splice(i, 1);
//         setImages([ ...images ]);
//     }

//     React.useEffect(() => {
//         if (message) {
//             const timer = setTimeout(() => setMessage(null), 2000);
//             return () => clearTimeout(timer);
//         }
//     }, [message]);

//     React.useEffect(() => {
//         if (images.length > 0)
//             handleImages(images);
//     }, [images, handleImages]);

//     return (
//         <div className="review-form">
//             <div className="attachment-form">
//                 <input type="file" name="photo" className="file" ref={refFile} onChange={_onAttach} />
//                 <button type="button" onClick={_onClick} disabled={disabled}>
//                     { message? message : <i className="fas fa-camera"></i> }
//                 </button>
//             </div>
//             { (images.length > 0) &&
//             <ul className="attachment-list">
//                 {/* { message && <li className="message">{message}</li> } */}
//                 { images.map((url, i) => 
//                     <li key={url}>
//                         <img src={url} alt="첨부 이미지" />
//                         <button type="button" className="btn-del" onClick={() => _onDelete(i)}>
//                             <i className="fas fa-minus-circle"></i>
//                         </button>
//                     </li>
//                 )}
//             </ul>
//             }
//         </div>
//     );
// }

const ReviewRatings = (props) => {
    const { handleChange } = props;
    const [ratings, setRatings] = useState(5);

    const onChange = (e) => {
        const v = parseInt(e.target.value);
        setRatings(v);
        handleChange(e);
    };

    return (
        <div className="review-form">
            <ul className="ratings">
                <li>
                    <input type="radio" id="rdUp" name="_ratings" value={5} onChange={onChange} checked={ratings === 5} />
                    <label htmlFor="rdUp">
                        {/* <i className="far fa-thumbs-up"></i> */}
                        👍
                        <span className="up">추천</span>
                    </label>
                </li>
                <li>
                    <input type="radio" id="rdDown" name="_ratings" value={1} onChange={onChange} checked={ratings === 1} />
                    <label htmlFor="rdDown">
                        {/* <i className="far fa-thumbs-down"></i> */}
                        👎
                        <span className="down">보통</span>
                    </label>
                </li>
            </ul>
        </div>
    )
}

const ReviewForm = (props) => {
    const { id, onSubmit } = props;
    const { backend } = useContext(ttContext);
    const [disabled, setDisabled] = useState(true);
    const [disabledAttach, setDisabledAttach] = useState(false);
    const [inputs, setInputs] = useState({ _message: '', _ratings: "5" });
    const [images, setImages] = useState([]);
    const [message, setMessage] = useState(null);
    const refFile = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        setDisabled(true);
        backend.insertReview({ _sid: id, ...inputs, _images: images })
            .then((res) => {
                setInputs({ _message: '' });
                setImages([]);
                onSubmit(res);
            })
            .catch((err) => console.error(err));
    };

    const handleChange = (e) => {
        e.persist();
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    };

    const _onAttachClick = () => {
        if (images.length < 4)
            refFile.current.click();
    }

    const _onAttach = (e) => {
        setDisabledAttach(true);

        const file = e.target.files[0];
        const uploadTask = backend.uploadImage(file);

        uploadTask.on('state_changed',
            (snapshot) => {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if (snapshot.state === 'running')
                    setMessage(`업로드 중 ${progress.toFixed(0)}%`);
            },
            (err) => {
                console.error('_onAttach', err);
                setDisabledAttach(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((url) => {
                        images.push(url);
                        setImages([...images]);
                        setDisabledAttach(images.length === 4 ? true : false);
                    });
            }
        );
    }

    const _onAttachDelete = (i) => {
        images.splice(i, 1);
        setImages([...images]);
        setDisabledAttach(false);
    }

    React.useEffect(() => {
        const { _message } = inputs;

        if (_message && _message.length > 2 && _message.length <= 50)
            setDisabled(false);
        else
            setDisabled(true);
    }, [inputs]);

    React.useEffect(() => {
        if (message) {
            const timer = setTimeout(() => setMessage(null), 2000);
            return () => clearTimeout(timer);
        }
    }, [message]);

    return (
        <div className="post-form">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <label>리뷰 작성</label>
                    <input name="_message" onChange={handleChange} value={inputs._message} placeholder={"솔직한 한 줄 리뷰"} minLength="2" maxLength="50" autoComplete="off" required />
                </div>
                <ReviewRatings handleChange={handleChange} />
                <div className="review-form">
                    {(images.length > 0) &&
                        <ul className="attachment-list">
                            {images.map((url, i) =>
                                <li key={url}>
                                    <img src={url} alt="첨부 이미지" />
                                    <button type="button" className="btn-del" onClick={() => _onAttachDelete(i)}>
                                        <i className="fas fa-minus-circle"></i>
                                    </button>
                                </li>
                            )}
                        </ul>
                    }
                    <div className="attachment-form">
                        <input type="file" accept="image/*" name="photo" className="file" ref={refFile} onChange={_onAttach} />
                        <button type="button" onClick={_onAttachClick} disabled={disabledAttach}>
                            {message ? message : <>사진 올리기</>}
                        </button>
                    </div>
                </div>
                <div className="row">
                    <button type="submit" disabled={disabled}>등록</button>
                </div>
            </form>
        </div>
    );
};

const ReviewList = (props) => {
    const { data } = props;
    const { user, backend } = useContext(ttContext);
    const [isLoading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [reviews, setReviews] = useState(null);

    useEffect(() => {
        if (user || refresh) {
            backend.reviewsQuery(data._id)
                .then((_reviews) => {
                    setReviews([..._reviews]);
                    // backend.getGoogleReviews(data, (_greviews) => {
                    // setReviews([..._reviews, ..._greviews]);
                    // });
                })
                .catch(() => setReviews([]));
        }
    }, [refresh, backend, data, user]);

    useEffect(() => {
        setLoading((reviews) ? false : true);
        setRefresh(false);
    }, [reviews]);

    const deleteReview = (_rid) => {
        window.OTKT.sendEvent({ event: 'DELETE_REVIEW', id: _rid })
        backend.deleteReview(_rid)
            .then((r) => setRefresh(r))
            .catch(() => setRefresh(true));
    };

    // if (backend.external) {
    //     return (
    //         <div className="message">
    //             <a target="_blank" rel="noopener noreferrer" className="span-msg error" href={`https://teetime.cc/${data._id}`}><span>골프 정보 플랫폼 티타임</span>에서 확인하세요.</a>
    //         </div>
    //     );
    // }
    // else 
    if (!user) {
        return (
            <div className="message">
                {/* <span className="span-msg">로그인하고 골프장 리뷰를 확인하세요.</span> */}
                <AuthButton />
            </div>
        );
    }
    else {
        return (
            <section className="section-reviews">
                <ul className="review-list">
                    {isLoading &&
                        <li className="loading"><div className="loading-wrap"><div className="icon-loading"></div></div></li>
                    }
                    {(!isLoading && reviews && reviews.length === 0) &&
                        <li className="empty">첫 리뷰를 작성해보세요!</li>
                    }
                    {(!isLoading && reviews) && reviews.map((post) => {
                        const _ratings = post._ratings ? 'r' + post._ratings : 'r0';
                        const _ts = post._ts ? toDate(post._ts.toDate()) : '';

                        return (
                            <li key={post._id} className={'post'}>
                                <div className="post-content">
                                    <div className="post-message">
                                        <p>{post._message}</p>
                                    </div>
                                    {(post._images && post._images.length > 0) &&
                                        <div className="post-image">
                                            {post._images.map((image, i) =>
                                                <img key={image} src={image} alt="리뷰 이미지" />
                                            )}
                                        </div>
                                    }
                                    <div className="post-top">
                                        {post._ratings > 1 && <div className={`ratings ${_ratings}`}>추천 👍</div>}
                                        <div className="profile">
                                            <span className="date">{_ts}</span>
                                            {(post._uid === user.uid) && <button className="btn delete" onClick={() => deleteReview(post._id)}>삭제</button>}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <ReviewForm id={data._id} onSubmit={setRefresh} />
            </section>
        );
    }
};

export default ReviewList;