import * as React from 'react';
import { BadgeType, BadgeTag, BadgeThumbnail } from './Badges';
import ttContext from '../class/AppContext.jsx';

const SearchList = (props) => {
    const { data } = React.useContext(ttContext);

    const handleClick = (dataKey) => {
        props.onKeySelect(dataKey);
    }

    let noResult = true;

    return (
        <div className="search-list">
            <ul>
                {/* { isLoading &&
                    <li className="loading"><div className="icon-loading"></div></li>
                } */}
                {data &&
                    data.map((row) => {
                        const id = row._id;
                        const hasThumbnail = row._info.thumbnail || (row._info.images && row._info.images.length > 0) ? 'thumbnail' : false;
                        const urlThumbnail = row._info.thumbnail ? row._info.thumbnail : `https://s3.ap-northeast-2.amazonaws.com/img.teetime.cc/thumbnails/${id.toLowerCase()}.png`;

                        if (row.hidden)
                            return null;
                        else {
                            noResult = false;

                            return (
                                <li key={id}>
                                    <button onClick={() => handleClick(id)} className={`btn ${hasThumbnail}`}>
                                        {hasThumbnail &&
                                            <div className="img">
                                                <img
                                                    className="img-thumbnail"
                                                    src={urlThumbnail}
                                                    alt={row._name}
                                                    // onError={(e) => {e.target.src="/icon-tt.png"; e.target.onerror = null;}} 
                                                    onError={(e) => { e.target.src = "/icon-512-maskable.png"; e.target.onerror = null; }}
                                                />
                                            </div>
                                        }
                                        {!hasThumbnail &&
                                            <BadgeThumbnail data={row} />
                                        }
                                        <div className="col">
                                            <div className="title">
                                                <span className="name">{row._name}</span>
                                                <BadgeType data={row} />
                                            </div>
                                            <span className="desc">
                                                {row._address ? row._address.address_name.substr(0, 15) : ''}
                                            </span>
                                            <div className="badges">
                                                <BadgeTag tags={row._tags} />
                                            </div>
                                        </div>
                                    </button>
                                </li>
                            );
                        }
                    })
                }
                {(data && noResult) &&
                    <li className="empty">검색 결과가 없습니다</li>
                }
                <li className="btn">
                    <button className="btn-refresh" onClick={props.refreshMap} title="현재 위치에서 다시 검색">
                        <i className="fas fa-redo-alt"></i><span>현재 지도 위치 다시 검색</span>
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default SearchList;